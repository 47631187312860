<template>
  <div>
    <!-- Header -->
    <div class="header bg_maroon py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">

            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon fill="#EDF0F5" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container pb-5" style="margin-top: -15rem">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card  border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-5 mt-5">
                <img src="/img/logo_new.svg" height="40" width="340" alt="">
              </div>
              <div class="text-center text-muted mb-5 mt-5">
                <h2>FORGOT PASSWORD ?</h2>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="api_param.email">
                  </base-input>

                  <div class="text-center pb-0">
                    <base-button :loading="loader" type="submit" native-type="submit"
                                 class="mt-4 mb-0 width_100 auth_orange_button">Reset Password
                    </base-button>
                  </div>
                </form>
                <div class="text-center">
                  <router-link to="/answer-question" class=" black_txt_under_orange_btn"><h3 class="mt-2 text-center">
                    Back to login</h3></router-link>
                </div>
              </validation-observer>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import authHelper from "../../util/authHelper";

  export default {
    data() {
      return {
        api_param: {
          email: '',
        },
        loader: false
      };
    },
    methods: {
      onSubmit() {
        this.loader = true;
        let vm = this;
        axios.post(this.$store.getters.getBaseUrl + '/api/reset-password-request',
          this.api_param,
        ).then((response) => {
          vm.$store.commit('toggleSessionCheck', false)
          vm.$store.commit('updateSessionTime', 0)
          vm.loader = false;
          vm.api_param.email = null;
          vm.$notify.success({
            title: 'Success',
            message: 'Please check your email for the password reset link.'
          });
          vm.$router.push({name: 'Login'});
        }).catch(error => {
          var message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.loader = false;
          vm.$notify.error({
            title: 'Error',
            message: message
          });
        });
      }
    }
  };
</script>
<style>
  .auth_orange_button:hover {
    color: white;
  }
</style>
