<template>
  <div class="terms-and-condition">
    <strong>
      Terms of use
    </strong>
    <p>
      Please read this Agreement carefully, by accessing, downloading, copying or
      otherwise using the
      App, you acknowledge that you have read this Agreement, understand it, and agree to be bound by
      its terms and conditions. This agreement shall become effective as of the date you access,
      download, copy or use the App, whichever happens first. EITHER YOU OR THE COMPANY MAY TERMINATE
      THIS AGREEMENT AT ANY TIME, AFTER WHICH ANY AND ALL NOTIFICATIONS OR DATA ASSOCIATED WITH YOUR
      PROFILE MAY BE DELETED AT THE COMPANY"S DISCRETION. THESE TERMS OF USE REQUIRE THAT YOU SUBMIT
      TO THE LAW AND JURISDICTION OF STATE OF TEXAS. IF YOU DO NOT AGREE TO THESE TERMS DO NOT ACCESS,
      DOWNLOAD, COPY OR USE THE APP.
    </p>
    <h3>
      1. Definitions
    </h3>
    <p>
      For the purposes of this Agreement:
    </p>
    <p>
      1.1. "App" shall refer to Baritastic.
    </p>
    <p>
      1.2. "Company" "us," "we," "our" and grammatical variants thereof shall
      collectively refer to NBL
      Media, LLC, a limited liability company organized and existing under the laws of the State of
      Texas. and its assigns and successors in interest.
    </p>
    <p>
      1.3. "Data" shall mean any data that you enter on or into the App or your
      Profile.
    </p>
    <p>
      1.4. "HIPAA" shall mean the Health Insurance Portability and Accountability
      Act of 1996.
    </p>
    <p>
      1.5. "Laws" shall mean the laws, statutes, and regulations then in effect
      of the United States of
      America and its various states and dependencies as well as the laws of your country of residence
      or the country in which you use or access the App and the laws of any provinces, states or
      dependencies thereof.
    </p>
    <p>
      1.6. "Parties" shall collectively refer to the Company and you.
    </p>
    <p>
      1.7. "Profile" shall collectively refer to the user profile that you create
      in order to access
      the App.
    </p>
    <p>
      1.8. "Provider" and grammatical variants thereof shall refer to a health
      care professional (an
      individual, or an organization of individuals) who may provide bariatric and bariatric related
      services to users of the App.
    </p>
    <p>
      1.9. "Suspend" or "Suspension" shall include the disabling of your Profile
      and/or the cessation
      of transmission of data via the App.
    </p>
    <p>
      1.10. "You", "you", "your" and grammatical variants thereof shall refer to
      users of the App.
    </p>
    <h3>
      2. Term and Termination
    </h3>
    <p>
      This Agreement is effective until terminated. You or the Company may
      terminate this Agreement at
      any time for any reason, with or without cause. You may terminate by disabling your Profile,
      uninstalling and destroying all copies of the App. The Company may immediately terminate this
      Agreement at any time at its sole discretion with or without notice to you. Additionally, your
      rights under this Agreement will terminate automatically if you fail to comply with any term(s)
      of this Agreement. Upon termination, all legal rights and licenses granted to you hereunder
      shall terminate immediately and you shall cease all use of the App, uninstall and destroy all
      copies of the App and any related documentation.
    </p>
    <h3>
      3. Description
    </h3>
    <p>
      Subject to and conditioned upon the Company"s retained rights and all other
      terms and conditions
      set forth in this Agreement, the Company offers the App as soon as practicable after
      registration. In order to access the App you will need to register a Profile by providing your
      starting weight, target weight and height. You will also have the option to enter in additional
      information such as name, email and phone number, if you so choose. You are responsible for
      maintaining the confidentiality of both your password and your Profile and are fully responsible
      for all activities that occur under your password and your Profile. You agree that you will not
      store any sensitive data including but not limited to, social security numbers, credit card
      data, or any other personal identifiable information except those required to create your
      Profile. You agree to immediately notify the Company of any unauthorized uses of your Profile or
      the App or any other breaches of security. The Company cannot and will not be liable for any
      loss or damage from your failure to comply with this security obligation. You acknowledge and
      agree that under no circumstances will the Company be liable, in any way, for any acts or
      omissions by you, including any damages of any kind incurred as a result of such acts or
      omissions.
    </p>
    <p>
      With this App you can:
    </p>
    <ul>
      <li>Track your weight loss</li>
      <li>Create a photo timeline of your success</li>
      <li>Track steps using wearble devices</li>
      <li>Access recipes</li>
      <li>Track Nutrition</li>
      <li>Access our calendar of events</li>
      <li>Set-Up vitamin and supplement reminders</li>
      <li>Create a checklist of items required before surgery</li>
    </ul>
    <p>
      Use of the App is subject to the following conditions and
      restrictions:
    </p>
    <p>
      The information provided by the App is for informational convenience only.
      Before committing to
      any course of treatment you should consult with your doctor. You are expected to rely on your
      own judgment and the advice of your doctor before beginning or participating in any medical
      course of treatment. The App is not intended to serve as medical advice. Use of the App does not
      create a doctor-patient relationship. Do not use the App to communicate specific information
      related to your condition, symptoms, ongoing treatment or other specific health care
      information. IF YOU NEED IMMEDIATE MEDICAL ASSISTANCE OR HELP, PLEASE CALL 911.
    </p>
    <p>
      3.1. The App
    </p>
    <p>
      3.1.1. The Company hereby grants, and you hereby accept, a nontransferable,
      revocable,
      non-sublicensable, and non-exclusive, limited license to download and use the App and all
      related documentation for your own personal, non-commercial use during the term of this
      Agreement. The App is licensed, not sold, and any rights not expressly granted herein shall be
      reserved for the Company. Source code or other information pertaining to the logic design of the
      App is specifically excluded from the license granted hereunder.
    </p>
    <p>
      3.1.2. You recognize that the App and all related information, including
      but not limited to any
      and all updates, improvements, modifications, enhancements, and information related to
      installation of the App, are proprietary, and that all rights thereto, including copyright, are
      owned by the Company or, if sublicensed by the Company, by the respective owners of the App. You
      further acknowledge that you have been advised that the App, including updates, improvements,
      modifications, enhancements, and information related to installation, constitutes a trade secret
      of the Company, is protected by civil and criminal law, and by the law of copyright, is valuable
      and confidential to the Company, and that its use and disclosure must be carefully and
      continuously controlled.
    </p>
    <p>
      3.1.3. The Company or, if sublicensed by the Company, the respective owners
      of the App shall at
      all times retain title to the App and all related information, including all updates,
      improvements, modifications and enhancements, furnished to you hereunder.
    </p>
    <p>
      3.1.4. The App supplied hereunder is for your personal, non-commercial use.
      The App is to be used
      only for the purposes specified in this Agreement and specifically as restricted in the
      following two subparagraphs of this Section 3.
    </p>
    <p>
      3.1.4.1. You will not: (i) reproduce, copy, distribute or make the App or
      related documentation
      available over a network where it could be used by multiple devices at the same time, or permit
      anyone else to reproduce, copy, distribute or make the App or related documentation available
      over a network where it could be used by multiple devices at the same time whether such App or
      documentation is in written, magnetic or any other form, except pursuant to reasonable backup
      procedures, or for your use pursuant to this Agreement, nor; (ii) create or attempt to create,
      or permit others to create or attempt to create, by disassembling, reverse engineering or
      otherwise, the source programs or any part thereof from the object program or from other
      information (whether oral, written, tangible or intangible) made available to you under this
      Agreement.
    </p>
    <p>
      3.1.4.2. You agree to notify the Company forthwith if you obtain
      information as to any
      unauthorized possession, use or disclosure of the App or documentation by any person or entity,
      and further agree to cooperate with the Company at the Company"s expense, in protecting the
      Company"s proprietary rights.
    </p>
    <p>
      3.1.5.. The Company may provide you with the ability to or otherwise
      necessitate the download of
      certain third-party software (the "Third Party Software"). The license conditions governing the
      use of the Third Party Software may differ from the Company"s own software licenses. Customers
      of the Company are bound by the conditions of all licenses pertaining to such Third Party
      Software and should make themselves familiar with their terms and conditions. THE PROVISION AND
      OFFERING OF SUCH THIRD PARTY SOFTWARE BY THE COMPANY DOES NOT CONSTITUTE AN ENDORSEMENT OF THE
      THIRD PARTY SOFTWARE, NOR CAN THE COMPANY MAKE ANY REPRESENTATIONS OR WARRANTIES REGARDING THE
      USE AND FUNCTIONALITY OF SUCH THIRD PARTY SOFTWARE.
    </p>
    <p>
      3.1.6. In the event of termination of this Agreement, or upon any act which
      shall give rise to
      the Company"s right to terminate, or upon the expiration of the license for the App which is
      subject to a limited-duration license, any and all licenses granted under this Section 3.1 shall
      terminate automatically, and you will remove, erase or destroy the App and documentation and all
      copies thereof, wherever located, without demand or notice.
    </p>
    <p>
      3.1.7. The Company may stop providing the App or any updates thereto,
      including but not limited
      to the Third-Party Software, at any time without notice or any further liability to you.
    </p>
    <p>
      3.2 Data Privacy
    </p>
    <p>
      3.2.1. The information you provide with the App will be used solely for
      facilitating contact with
      your existing or potential Provider or for personally tracking your condition and/or progress.
      The App is not a healthcare provider, healthcare clearing house, or health plan. None of the
      Data tracked or stored by the App is individually identifiable health information, and is not
      subject to the requirements of HIPPA.
    </p>
    <p>
      3.2.2. Your information will not be used by the App for any purpose other
      than facilitating
      communication between you and a Provider. The App and the Company are committed to patient
      privacy but cannot control the use of your mobile device. Some of the App features may
      prepopulate your information as a convenience, but the App nor the Company will be responsible
      should your device be lost or stolen, nor is Company responsible for any network security
      related to internet communications or cellular communications. If you have any concerns about
      your privacy or the security of your information, you should not download, install or use the
      App.
    </p>
    <p>
      3.3. Independence from Platforms
    </p>
    <p>
      3.3.1. The App is independent of any platform on which it is located. The
      App is not associated,
      affiliated, sponsored, endorsed or in any way linked to any platform operator, including,
      without limitation, Apple, Google, Android or RIM Blackberry (each being an "Operator").
    </p>
    <p>
      3.3.2. Your download, installation, access to or use of the App is also
      bound by the terms and
      conditions of the Operator.
    </p>
    <p>
      3.3.3. You and the Company acknowledge that this Agreement is concluded
      between you and the
      Company only, and not with an Operator, and the Company, not those Operators, is solely
      responsible for the App and the content thereof to the extent specified in this
      Agreement.
    </p>
    <p>
      3.4. Additional Features
    </p>
    <p>
      3.4.1. The App may contain additional features such as the Connect to My
      Program ("Program")
      which may require compliance with additional terms.
    </p>
    <p>
      3.4.2. If you choose to connect to this Program, you allow the Program to
      view your weight loss,
      exercise, and nutrition data that you log into the App. The Program will also be able to see
      your name, email address, phone number, and surgery date (if entered). By connecting the App to
      the Program you explicitly allow this Program and members of its team to review this information
      as needed to make better recommendations based on your diet and weight loss data. Data is
      transferred and presented in a manner Compliant with HIPPA guidelines. Use of this feature does
      not indicate that you are a current patient, previous patient nor does it establish a
      relationship with the Provider.
    </p>
    <p>
      3.4.3. The Program may send messages from time to time. These messages will
      not include specific
      medical information but they may advocate for a proper diet and exercise habits in congruence
      with the Program guidelines. You may receive notification about upcoming events (i.e. support
      group reminders).
    </p>
    <h3>
      4. No Warranties by the Company
    </h3>
    <p>
      THE COMPANY AGGREGATES INFORMATION THROUGH VARIOUS PUBLICLY AVAILABLE
      RESOURCES AND INFORMATION
      PROVIDED BY YOUR DOCTOR, CLINIC, OR HEALTHCARE PROVIDER. THE APP AND INFORMATION PRESENTED VIA
      THE APP, INCLUDING TEXT, GRAPHICS, AUDIO, VIDEO, LINKS OR OTHER DIGITAL MEDIA ARE PROVIDED ON AN
      "AS IS" BASIS WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. YOU EXPRESSLY AGREE THAT THE USE
      OF THE APP IS AT YOUR SOLE RISK. THE COMPANY DOES NOT WARRANT THAT THE APP WILL BE UNINTERRUPTED
      OR ERROR FREE, NOR DOES THE COMPANY MAKE ANY WARRANTY AS TO ANY RESULTS THAT MAY BE OBTAINED BY
      USE OF THE APP. NO WARRANTY IS MADE BY THE COMPANY REGARDING ANY INFORMATION, SERVICES OR
      PRODUCTS PROVIDED THROUGH OR IN CONNECTION WITH THIS AGREEMENT, AND THE COMPANY HEREBY EXPRESSLY
      DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING WITHOUT LIMITATION: (1) ANY WARRANTIES AS TO THE
      AVAILABILITY, QUALITY, QUANTITY, OR CONTENT OF INFORMATION, SERVICES OR GOODS PROVIDED TO YOU
      HEREUNDER; AND (2) ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. THE
      COMPANY DOES NOT GUARANTEE THAT ANY CONTENT, INFORMATION, SOFTWARE OR OTHER MATERIAL ACCESSIBLE
      THROUGH THE APP WILL BE FREE OF VIRUSES, "WORMS", "TROJAN HORSES", OR OTHER HARMFUL
      COMPONENTS.
    </p>
    <h3>
      5. Company"s Limited Liability
    </h3>
    <p>
      YOUR SOLE AND EXCLUSIVE REMEDY HEREUNDER SHALL BE FOR YOU TO DISCONTINUE
      YOUR USE OF THE APP AND
      TERMINATE THIS AGREEMENT. IN NO CASE SHALL THE COMPANY, ITS DIRECTORS, OFFICERS, EMPLOYEES,
      AFFILIATES, AGENTS OR CONTRACTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY,
      SPECIAL OR CONSEQUENTIAL DAMAGES ARISING FROM YOUR USE OF OR OTHERWISE RELATING TO THE APP. SOME
      COUNTRIES, STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR
      CONSEQUENTIAL OR INCIDENTAL DAMAGES. IN SUCH COUNTRIES, STATES OR JURISDICTIONS, THE COMPANY"S
      LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. EXCEPT AS EXPRESSLY SET FORTH IN ANY
      SEPARATE SOFTWARE LICENSE OR IN THIS AGREEMENT, THE COMPANY DOES NOT ENDORSE, WARRANT OR
      GUARANTEE ANY THIRD-PARTY PRODUCT OR SERVICE OFFERED OR OTHERWISE ACCESSED USING THE APP, AND
      THE COMPANY WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
      BETWEEN YOU AND ANY THIRD PARTIES. YOU HEREBY RELEASE THE COMPANY FROM ANY AND ALL OBLIGATIONS,
      LIABILITY AND CLAIMS IN EXCESS OF THESE LIABILITY LIMITATIONS. THE TOTAL LIABILITY OF THE
      COMPANY FOR BREACH OF WARRANTY ARISING OUT OF CONTRACT, NEGLIGENCE OR STRICT LIABILITY IN TORT,
      OR ANY OTHER CLAIM RELATING TO THIS AGREEMENT SHALL BE LIMITED TO TEN DOLLARS ($10).
    </p>
    <h3>
      6. Your Additional Obligations and Warranties
    </h3>
    <p>
      6.1 You acknowledge that only you may use the App and/or your Profile and
      you agree and warrant
      that you shall not permit anyone else to use the App and/or your Profile or authorize any third
      party to access the App and/or your Profile on your behalf. You are responsible for all activity
      that takes place with respect to the App and/or your Profile, and you agree that in the event
      the Company believes or has reason to believe, in its sole discretion, that you have breached
      this Agreement or any of the warranties in this Section 6, the Company may, without prior notice
      to you and in the Company"s sole and exclusive discretion, suspend the use of the App and/or
      your Profile and/or terminate this Agreement, without any liability of any kind. As more
      completely set forth in Sections 5, 6 and 9, you waive any and all claims you may have, now and
      forever, against the Company relating to any action taken under this Section 6.1, and agree to
      indemnify and hold harmless the Company from and against any claims brought by third parties as
      a result of your Data or your use of the App.
    </p>
    <p>
      6.2. You agree and warrant that your use of the App, shall at all times
      comply with your
      warranties under this Section 6 as well as all relevant laws.
    </p>
    <p>
      6.3. You agree and warrant that you will not use the App to transmit
      messages which: display,
      contain or link to any harmful matter or indecent materials or communications which are
      available to, or accessible by, minors; display or contain any material that consists of
      pornography, child pornography, or other obscene content, including but not limited to content
      involving or by a performer who is portrayed or made to appear as a person under the age of
      eighteen years of age or which could otherwise result in harm to minors, all as determined in
      the Company"s sole discretion.
    </p>
    <p>
      6.4. You agree and warrant that you shall not damage, disable, overburden,
      or impair the App;
      interfere with anyone else"s use or enjoyment of the App; or interfere with, defame, or abuse
      the Company or its officers, employees, or agents.
    </p>
    <p>
      6.5. You affirmatively represent, agree and warrant that your Data and the
      use or distribution of
      your Data does not infringe the intellectual property rights of others, including, but not
      limited to, copyrights, trademark and service mark rights, patent rights and rights of
      publicity, both in the United States and throughout the world.
    </p>
    <p>
      6.6. You agree and warrant that your Data shall not constitute or contain
      or link to material:
      which is libelous, slanderous, or defamatory; which violates the right of publicity or privacy
      of any party; or which will violate or infringe upon or will otherwise give rise to any adverse
      claim with respect to any common law or other right of any person or other entity, including,
      without limitation, privacy rights and all other personal and proprietary rights.
    </p>
    <p>
      6.7. You agree and warrant that your Data shall not contain or link to any
      material which is
      offensive, harmful, violent, threatening, abusive or hateful, in the Company"s sole
      discretion.
    </p>
    <p>
      6.8. You agree and warrant that any and all material(s) of every kind which
      you store or transmit
      using the App shall at all times be free from any and all damaging software defects, including,
      but not limited to, software "viruses", "worms", "Trojan Horses," and other source code
      anomalies, which may cause software or hardware disruption or failure, reduced computer
      operating speed, or compromise any security system.
    </p>
    <p>
      6.9. You agree and warrant that you shall not engage in any false,
      deceptive or fraudulent
      activities in association with your use of the App.
    </p>
    <p>
      6.10. You agree and warrant that you shall not resell or redistribute the
      App or any part
      thereof, including but not limited to your Profile, notifications, alerts, or use any
      unauthorized means to modify or reroute the App (or to attempt same).
    </p>
    <p>
      6.11. You represent and warrant that you are over eighteen years of age and
      are fully competent
      to enter into this Agreement. To download, install, access or use the App, you must be 18 years
      of age or over. If you are under 18 and you wish to use download, install, access or use the
      App, you must get consent from your parent or guardian before doing so.
    </p>
    <p>
      6.12. You represent and warrant that you are not a national or resident of
      any country subject to
      U.S. Treasury Department embargo restrictions, and that you are not listed in the "Entity List"
      or "Denied Persons List" maintained by the US Department of Commerce or the list of "Specially
      Designated Nationals and Blocked Persons" maintained by the US Department of Treasury. You
      further acknowledge that you are not a national or resident of a country whose name is otherwise
      omitted from the registration form for the Company Services. Residents of countries which are
      serviced by a Company affiliate are required to contract with those Company affiliates, and you
      represent and warrant that you are not a resident of one of those countries.
    </p>
    <p>
      6.13. You agree to abide by United States and other applicable export
      control laws and you
      further agree not to upload to your Profile any data or software that cannot be exported without
      prior written government authorization.
    </p>
    <p>
      6.14. You grant to the Company and to the Company"s Affiliates a
      perpetual, irrevocable,
      fully-paid up, nonexclusive, transferable, and worldwide license to use all of your Data in the
      performance of the Company services and in any other manner related thereto or otherwise
      permitted under this agreement.
    </p>
    <h3>
      7. Confidentiality, Trademark and Copyright
    </h3>
    <p>
      7.1 "BariApps" and "Baritastic" are service marks of the Company, all
      rights reserved. The
      trademarks, logos, and service marks displayed on this app (collectively, the "Marks") belong to
      the Company and/or its affiliates or third parties which have licensed those rights to the
      Company ("Partners"); The Company and Partners retain all rights to the Marks and nothing in
      this Agreement grants you or anyone else any right whatsoever to the use of the Marks. You may
      not use, reproduce, or display any Marks without their owner"s prior written consent. All other
      trademarks, product names, and company names and logos appearing on the Company"s web site and
      app are the property of their respective owners.
    </p>
    <p>
      7.2 Unless expressly stated otherwise on the Company web site, you should
      assume that all
      content, images, and materials appearing on this app (collectively the "Company Content") are
      the sole property of the Company. Both U.S. and international copyright and other intellectual
      property laws and treaties protect such Company Content. You may not use, reproduce, display, or
      sell any Company Content without the Company"s prior written consent. You may not link to any
      page or frame any portion of the Company"s website or app in such a way as to remove, cover,
      alter, or obscure the Company"s trademarks or as would otherwise confuse viewers as to the
      origin of the content.
    </p>
    <h3>
      8. Your Indemnification of the Company
    </h3>
    <p>
      You agree that you shall fully defend and indemnify the Company, including
      its officers,
      directors, owners, managing agents, attorneys, shareholders, related entities, heirs, and
      assigns, from any and all claims, demands, actions, suits, losses, liabilities, damages,
      injuries, fines penalties, costs and expenses, attorneys" fees, arbitration fees, mediation
      fees, expert expenses, and all other consequences of every kind, directly or indirectly
      resulting from any and all failure(s) of you or your agent(s) to fully comply with all duties,
      obligations and other provisions set forth in this Agreement, including, but not limited to,
      your warranties set forth in Section 6 or your violation of a third party"s intellectual
      property rights. You further agree to defend, indemnify and hold harmless the Company, including
      its officers, directors, owners, managing agents, attorneys, shareholders, related entities,
      heirs, and assigns, from and against any and all claims, demands, actions, suits, losses,
      liabilities, damages, injuries, fines, penalties, costs and expenses, including, without
      limitation, reasonable attorneys" fees, arising out of any property damage or recoverable
      economic loss incurred by a third party, to the extent such damage or loss is caused by any act
      or omission of you or your agents in connection with the performance of this Agreement. You
      agree that the Company shall have the right to participate in the defense of any such claim
      through counsel of its own choosing at your expense.
    </p>
    <h3>
      9. No Joint Venture or Partnership
    </h3>
    <p>
      Nothing in this Agreement is intended by the Parties to create or
      constitute an agency, joint or
      collaborative venture, or partnership of any kind between the Company and you, nor shall
      anything in this Agreement be construed as constituting or creating any such agency, joint or
      collaborative venture, or partnership between the Company and you. The Company shall have no
      control or ownership interests of any kind in your business. The Company shall have no direct
      financial or other interest in, nor in any way "own" any online venture pertaining to your use
      of the App. The Company"s relationship to you shall be restricted to matters pertaining to the
      provision of the App as set forth in this agreement.
    </p>
    <h3>
      10. Services Rendered on a Non-Exclusive Basis
    </h3>

    <p>
      Any and all services which are or may be provided to you by the Company
      pursuant to this
      Agreement, including the licensure of rights herein, are not exclusive and nothing in this
      Agreement shall limit or restrict the Company from providing similar services and granting
      similar licenses to third parties regardless of whether such third parties are competitors of
      you. Nothing in this Agreement shall limit or restrict the Company from engaging in any
      activities similar to yours or in competition with you.
    </p>
    <h3>
      11. Privacy
    </h3>
    <p>
      11.1. The Company uses and protects any personal information collected from
      you as outlined in
      its Privacy Policy. As more specifically described therein, the Company may disclose your
      information as it deems necessary, in its sole discretion, to:
    </p>
    <p>
      11.1.1. comply with legal process or other legal requirements;
    </p>
    <p>
      11.1.2. protect and defend the rights or property of the Company or its
      officers, agents,
      affiliates, licensees, and customers; or
    </p>
    <p>
      11.1.3. carry out its obligations under or enforce this Agreement;
      or
    </p>
    <p>
      11.1.4. investigate violations of this Agreement or assist with criminal or
      civil
      investigations.
    </p>
    <p>
      11.2. INTERNATIONAL CUSTOMERS UNDERSTAND AND AGREE THAT THE COMPANY MAY
      DISCLOSE PERSONAL
      INFORMATION ABOUT THEM AND THEIR PROFILE PURSUANT TO THIS SECTION, AND WAIVE ANY RIGHTS TO
      PRIVACY OR PROTECTION OF PERSONAL DATA RELATING TO SUCH INFORMATION TO THE FULLEST EXTENT
      PERMITTED UNDER APPLICABLE LAW INCLUDING BUT NOT LIMITED TO THE LAW IN THE JURISDICTION YOU
      RESIDE.
    </p>
    <h3>
      12. Severability
    </h3>
    <p>
      In case any one or more of the provisions contained in this Agreement shall
      for any reason be
      held to be invalid, illegal or unenforceable in any respect, such invalidity, illegality or
      unenforceability shall not affect any other provision hereof, and this Agreement shall be
      construed as if such invalid, illegal or unenforceable provision(s) had never been included. The
      invalidity or unenforceability of any provision(s) of this Agreement shall not affect the
      validity or enforceability of any other provision.
    </p>
    <h3>
      13. Non-Enforcement Does Not Constitute Waiver
    </h3>
    <p>
      Failure of the Company at any time to enforce any of the specific
      provisions of this Agreement
      shall not preclude any other or further enforcement of such provision(s) or the exercise of any
      other right hereunder. No waiver of a breach of this Agreement shall be valid unless made in
      writing and signed by duly authorized representative of the Company.
    </p>
    <h3>
      14. Notices
    </h3>
    <p>
      14.1. The Company may provide notice to you via sms to the mobile phone or
      e-mail sent to the
      e-mail address associated with your Profile at the time such notice is sent. Such notice is
      deemed effective at the date and time of transmission, whether you receive it or not, and shall
      be deemed written notice for the purposes of this Agreement.
    </p>
    <p>
      14.2. You may provide notice to the Company by personal delivery; by
      addressing the notice as
      indicated below and depositing the same by registered or certified mail, postage prepaid, in the
      United States mail; or by Federal Express or other nationally-recognized courier. Such notice,
      statement or other document so delivered to the Company, except as this Agreement expressly
      provides otherwise, shall be conclusively deemed to have been given when first personally
      delivered, on the date of delivery or on the first date of receipt. Notice may not be sent to
      the Company via e-mail.
    </p>
    <h3>
      15. Force Majeure
    </h3>
    <p>
      15.1. In the event of "force majeure" (as defined below), the Company may
      terminate this
      Agreement without liability to you. For purposes of the Agreement, "force majeure" shall mean
      circumstances or occurrences beyond the Company"s reasonable control, whether or not foreseeable
      at the time of entering into the Agreement, in consequence of which the Company cannot
      reasonably be required to perform its obligations hereunder or otherwise perform its obligations
      under the Agreement. Such circumstances or occurrences include, but are not limited to: acts of
      God, war, civil war, insurrection, fires, floods, labor disputes, epidemics, governmental
      regulations and/or similar acts, embargoes, termination or temporary unavailability of any
      computer hardware or software, server, or network on which the App is located or maintained or
      through which the App is provided, and non-availability of any permits, licenses and/or
      authorizations required by governmental authority.
    </p>
    <p>
      15.2. The Company reserves the right at any time and from time to time to
      modify or discontinue,
      temporarily or permanently, the App (or any part thereof) with or without notice, including the
      right to cease all business operations in the United States or elsewhere. You agree that the
      Company shall not be liable to you or to any third party for any modification, suspension or
      discontinuance of the App.
    </p>
    <h3>
      16. No Assignment by You; Assignment by the Company
    </h3>
    <p>
      This Agreement and the rights pertaining hereto may not be assigned,
      resold, or otherwise
      transferred in whole or in part by you without the Company"s prior written consent. In
      particular, you may not sell accounts or subaccounts to third parties. Notwithstanding the
      above, this Agreement shall be binding upon your successors and assigns, if any. The Company may
      assign or license any or all of its rights and/or obligations hereunder in its free, sole, and
      unfettered discretion, without consent by or notice to you.
    </p>
    <h3>
      17. Jurisdiction, Venue, and Waiver of Jury Trial
    </h3>
    <p>
      17.1. YOU AGREE TO NEGOTIATE WITH THE COMPANY IN GOOD FAITH TO RESOLVE OR
      SETTLE ANY CLAIM OR
      DISPUTE IN ANY WAY RELATING TO OR CONCERNING THIS AGREEMENT.
    </p>
    <p>
      17.2. ANY AND ALL DISPUTES WHICH ARE NOT FIRST RESOLVED INFORMALLY MUST BE
      BROUGHT IN EITHER THE
      COURT OF TRAVIS COUNTY, TEXAS OR THE UNITED STATES DISTRICT COURT IN AUSTIN, TEXAS, WHICH COURTS
      SHALL HAVE EXCLUSIVE JURISDICTION OVER ANY LEGAL ACTION OR PROCEEDING ARISING OUT OF OR IN ANY
      WAY RELATING TO THIS AGREEMENT AND/OR YOUR ACCOUNT(S) WITH THE COMPANY. You and we irrevocably
      consent to personal jurisdiction and venue in such courts and you waive any challenge which you
      have or which may hereafter arise to personal jurisdiction or venue in such courts. You further
      agree that the Company shall be entitled to collect its attorneys" fees, costs and other
      expenses in the event that the Company acts to enforce this forum selection clause, regardless
      of whether the Company prevails in the underlying action.
    </p>
    <p>
      17.3. In addition to the foregoing, YOU HEREBY AGREE THAT AS A PART OF THE
      CONSIDERATION FOR THIS
      AGREEMENT, YOU WAIVE THE RIGHT TO A TRIAL BY JURY FOR ANY DISPUTE ARISING BETWEEN YOU AND THE
      COMPANY THAT IS IN ANY WAY RELATED TO THE SUBJECT MATTER OF THIS AGREEMENT AND/OR YOUR
      PROFILE(S) WITH THE COMPANY, and that such waiver shall be enforceable up to and including the
      day that trial is to start. Should any legal fees, costs, or other expenses be incurred by the
      Company with regard to enforcement of this jury waiver provision, the Company shall be entitled
      to recover such legal fees, costs, or other expenses without regard to whether the Company
      prevails in the underlying case.
    </p>
    <p>
      17.4. Neither you nor the Company may be a representative of other
      potential claimants or a class
      of potential claimants in any dispute concerning or relating to this Agreement, nor may two or
      more individuals" disputes be consolidated or otherwise determined in one proceeding. YOU AND
      THE COMPANY ACKNOWLEDGE THAT THIS SECTION WAIVES ANY RIGHT TO PARTICIPATION AS A PLAINTIFF OR AS
      A CLASS MEMBER IN ANY CLASS ACTION
    </p>
    <p>
      17.5. This Agreement shall be interpreted according to the laws of the
      State of Texas, without
      regard to conflicts of law principles.
    </p>
    <h3>
      18. Successors and Assigns
    </h3>
    <p>
      This agreement shall be binding upon and inure to the benefit of the
      Parties" respective heirs,
      personal representatives, executors, administrators, successors and assigns.
    </p>
    <h3>
      19. Entire Agreement
    </h3>
    <p>
      This Agreement constitutes the entire agreement of the Parties with respect
      to the subject matter
      hereof, and supersedes and cancels all other prior agreements, discussion, or representations,
      whether written or oral. No officer, employee or representative of the Company has any authority
      to make any representation or promise in connection with this Agreement or the subject matter
      thereof which is not contained expressly in this Agreement, and you hereby acknowledge and agree
      that you have not executed this Agreement in reliance upon any such representation or promise.
      This Agreement is solely for the benefit of you and the Company.
    </p>
    <h3>
      20. Modification
    </h3>
    <p>
      20.1. This Agreement may be materially altered by the Company by posting
      the new version of the
      Agreement at http://www.bariapps.com/privacy or by updating the App to incorporate the new terms
      of use and if posted in this manner, shall be effective immediately upon posting such notice.
      You are responsible for regularly reviewing information posted online to obtain timely notice of
      such changes. Your continued use of the App after changes are posted constitutes your acceptance
      of the amended Terms of Use.
    </p>
    <p>
      20.2. You may not modify this Agreement, in whole or in part, and any such
      modification or
      attempt to modify shall not be enforceable unless reduced to writing and signed by a duly
      authorized representative of the Company. No additional or conflicting term in any other
      document used by you will have any legal effect.
    </p>
    <h3>
      21. Statute of Limitations
    </h3>
    <p>
      You agree that regardless of any statute or law to the contrary, any claim
      or cause of action
      arising out of or related to use of the Service or this Agreement must be filed within one year
      after such claim or cause of action arose or be forever barred.
    </p>
    <h3>
      22. Contact Information
    </h3>
    <p>
      The Company is headquartered in Austin, TX. Correspondence sent by mail,
      courier, or personal
      service should be to the following address:
    </p>
    <p>
      NBL Media, LLC
    </p>
    <p>
      12805 Arbor Lake Cove
    </p>
    <p>
      Austin, Texas 78732
    </p>
  </div>
</template>
<script>
export default {
  name: 'terms-and-condition',
};
</script>
<style>
.terms-and-condition {
  font-family: 'Times New Roman';
  background-color: #f1f1f1;
  margin: 8px;
}
</style>
