<template>
</template>

<script>
  import authHelper from "../../util/authHelper";

  export default {
    data() {
      return {
        token: '',
      };
    },
    mounted() {
      let vm = this;

      let urlParams = new URLSearchParams(window.location.search);
      this.token = urlParams.get('token');

      if (! this.token) {
        vm.$router.push({name: 'Login'})
      }

      authHelper.setAuthToken(this.token, 'subUser');
      vm.$router.push({name: 'Dashboard'})
    },
    methods: {
    }
  };
</script>
