<template>
  <div>
    <!-- Header -->
    <div class="header bg_maroon py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">

            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon fill="#EDF0F5" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container pb-5" style="margin-top: -16rem; max-width: 1400px !important;">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card  border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-5 mt-5">
                <img src="/img/logo_new.svg" height="40" width="340" alt="">
              </div>
              <div class="text-center text-muted mt-5">
                <h2>Setup Security Question</h2>
              </div>
              <div class="text-center text-muted mb-5">
                <h3 class="font-weight-normal">On future logins we may ask you to answer one of these questions to confirm your identity</h3>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">

                  <div class="pt-3">
                    <base-input :rules="{required: true}" name="Question 1" class="mb-0">
                      <el-select class="width_100 mb-0 security-question-dropdown" v-model="api_param.question1"
                                 placeholder="Security question 1" autocomplete="off">
                        <el-option v-for="question in questions"
                                   v-if="question.id != api_param.question2 && question.id != api_param.question2"
                                   :key="question.id"
                                   :label="question.question_des"
                                   :value="question.id">
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input class=""
                                name="Answer 1"
                                :rules="{required: true}"
                                v-model="api_param.answer1"
                                placeholder="Answer">
                    </base-input>

                  </div>

                  <div class="pt-4">
                    <base-input :rules="{required: true}" name="Question 2" class="mb-0">
                      <el-select class="width_100 mb-0 security-question-dropdown" v-model="api_param.question2"
                                 placeholder="Security question 2">
                        <el-option v-for="question in questions"
                                   v-if="question.id != api_param.question1 && question.id != api_param.question3"
                                   :key="question.id"
                                   :label="question.question_des"
                                   :value="question.id">
                        </el-option>
                      </el-select>
                    </base-input>

                    <base-input class=""
                                name="Answer 2"
                                :rules="{required: true}"
                                v-model="api_param.answer2"
                                placeholder="Answer">
                    </base-input>
                  </div>

                  <div class="pt-4">
                    <base-input :rules="{required: true}" name="Question 3" class="mb-0">
                      <el-select class="width_100 security-question-dropdown" v-model="api_param.question3"
                                 placeholder="Security question 3">
                        <el-option v-for="question in questions"
                                   v-if="question.id != api_param.question1 && question.id != api_param.question2"
                                   :key="question.id"
                                   :label="question.question_des"
                                   :value="question.id">
                        </el-option>
                      </el-select>
                    </base-input>

                    <base-input class=""
                                name="Answer 3"
                                :rules="{required: true}"
                                v-model="api_param.answer3"
                                placeholder="Answer">
                    </base-input>
                  </div>

                  <div class="text-center pb-0">
                    <base-button :loading="loader" type="submit" native-type="submit" class="mt-4 mb-0 width_100 auth_orange_button">
                      Save
                    </base-button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import authHelper from "../../../util/authHelper";

  export default {
    data() {
      return {
        name: 'setup-question',
        questions: [],
        api_param: {
          question1: '',
          answer1: '',
          question2: '',
          answer2: '',
          question3: '',
          answer3: '',
        },
        loader: false,
      };
    },
    created() {
      this.fetchAllQuestions();
    },
    methods: {
      fetchAllQuestions() {
        const config = {
          // headers: { Authorization: `Bearer ${auth.getToken()}` }
          headers: {Authorization: `Bearer ${this.$store.getters.getAuthToken}`}
        };

        axios.get(this.$store.getters.getBaseUrl+'/api/all-security-questions',
          config
        ).then((response) => {
          console.log(response.data.data)
          this.questions = response.data.data;
        }).catch(error => {
          console.log(error.response)
        });
      },
      onSubmit() {
        let vm = this;
        vm.loader = true;
        let authToken = this.$store.getters.getAuthToken;
        const config = {
          headers: {Authorization: `Bearer ${authToken}`}
        };

        axios.post(this.$store.getters.getBaseUrl+'/api/save-answers',
          this.api_param,
          config
        ).then((response) => {
          authHelper.setAuthToken(authToken, 'subUser');
          authHelper.setIsPasswordChanged(response.data.data.isPasswordChanged);
          vm.$router.push({name: 'Dashboard'}).catch(()=>{});
        }).catch(error => {
          var message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Error',
            message: message
          }).finally(() => {
            vm.loader = false;
          });
        });
      },
    }
  };
</script>

<style scoped>
  .form-group {
    margin-bottom: 10px !important;
  }
</style>
<style>
div.security-question-dropdown > .el-input > .el-input__inner {
  font-weight: bold;
}

</style>
