<template>
  <div>
    <!-- Header -->
    <div class="header bg_maroon py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">

            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon fill="#EDF0F5" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container pb-5" style="margin-top: -8rem">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card  border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-5 mt-5">
                <img src="/img/logo_new.svg" height="40" width="340" alt="">
              </div>
              <div class="text-center text-muted mt-5">
                <h2>SECURITY QUESTIONS</h2>
              </div>
              <div class="text-center text-muted mb-5">
                <p>{{ this.question['question_des'] }}</p>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <input type="hidden" v-model="model.question_id ">
                  <base-input alternative
                              class="mb-3"
                              name="answer"
                              :rules="{required: true}"
                              placeholder="Answer"
                              v-model="model.answer">
                  </base-input>

                  <div class="text-center pb-0">
                    <base-button :loading="loader" type="submit" native-type="submit" class="mt-4 mb-0 width_100 auth_orange_button">
                      Submit
                    </base-button>
                  </div>
                </form>

              </validation-observer>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import authHelper from "../../../util/authHelper";

export default {
  data() {
    return {
      question: [],
      model: {
        answer: '',
        question_id: '',
      },
      loader: false,
    };
  },
  mounted() {
    if(!this.$store.getters.getAuthUser){
      window.Intercom("boot", {
        app_id: "jedq1yf9"
      });
    }
  },
  created() {
    this.getQuestions();
    if (!localStorage.hasOwnProperty('sideBarState')) {
      localStorage.sideBarState = false
    }
  },
  methods: {
    getQuestions() {
      let response = this.$store.getters.getQuestions;
      this.question = response.data.data.questions[Math.floor(Math.random() * 3)]
      this.model.question_id = this.question['id'];
    },
    onSubmit() {
      this.loader = true;
      let vm = this;
      this.$store.dispatch('_answerSecurityQuestion', this.model).then(response => {
        authHelper.setAuthToken(this.$store.getters.getAuthToken, 'subUser');
        authHelper.setRefreshToken(this.$store.getters.getRefreshToken);
        vm.$router.push({name: 'Dashboard'}).catch(()=>{});
      }).catch(error => {
        console.log(error.response);
        let message = 'Something went wrong please try again in few minutes.'
        if(error.response) {
          let status = error.response.status;
          message = error.response.data.message;
          status === 400 ? vm.$router.push({name: 'Login'}) : null;
        }
        vm.loader = false;
        vm.$notify.error({
          title: 'Error',
          message: message
        });
      });
    }
  }
};
</script>
